// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-colophon-js": () => import("./../src/pages/colophon.js" /* webpackChunkName: "component---src-pages-colophon-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-making-js": () => import("./../src/pages/making.js" /* webpackChunkName: "component---src-pages-making-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-blog-cat-list-js": () => import("./../src/templates/blog-cat-list.js" /* webpackChunkName: "component---src-templates-blog-cat-list-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-book-list-js": () => import("./../src/templates/book-list.js" /* webpackChunkName: "component---src-templates-book-list-js" */),
  "component---src-templates-book-post-js": () => import("./../src/templates/book-post.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-font-list-js": () => import("./../src/templates/font-list.js" /* webpackChunkName: "component---src-templates-font-list-js" */),
  "component---src-templates-font-post-js": () => import("./../src/templates/font-post.js" /* webpackChunkName: "component---src-templates-font-post-js" */),
  "component---src-templates-presentation-list-js": () => import("./../src/templates/presentation-list.js" /* webpackChunkName: "component---src-templates-presentation-list-js" */),
  "component---src-templates-presentation-post-js": () => import("./../src/templates/presentation-post.js" /* webpackChunkName: "component---src-templates-presentation-post-js" */),
  "component---src-templates-project-list-js": () => import("./../src/templates/project-list.js" /* webpackChunkName: "component---src-templates-project-list-js" */),
  "component---src-templates-project-post-js": () => import("./../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-quote-list-js": () => import("./../src/templates/quote-list.js" /* webpackChunkName: "component---src-templates-quote-list-js" */),
  "component---src-templates-quote-post-js": () => import("./../src/templates/quote-post.js" /* webpackChunkName: "component---src-templates-quote-post-js" */),
  "component---src-templates-resource-cat-list-js": () => import("./../src/templates/resource-cat-list.js" /* webpackChunkName: "component---src-templates-resource-cat-list-js" */),
  "component---src-templates-student-work-list-js": () => import("./../src/templates/student-work-list.js" /* webpackChunkName: "component---src-templates-student-work-list-js" */),
  "component---src-templates-student-work-post-js": () => import("./../src/templates/student-work-post.js" /* webpackChunkName: "component---src-templates-student-work-post-js" */)
}

